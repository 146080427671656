import { FC } from 'react';
import { handleGAEvent } from '~/utils/helpers';

import styles from './ArticleSeeMoreButton.module.scss';

interface Props {
  articleId: number;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArticleSeeMoreButton: FC<Props> = ({
  articleId,
  isExpanded,
  setIsExpanded,
}) => {
  return (
    <div className={styles.buttonHolder}>
      <div
        className={styles.fade}
        style={{ display: isExpanded ? 'none' : 'block' }}
      ></div>
      <button
        className={styles.button}
        onClick={(e) => {
          e.preventDefault();
          if (isExpanded) {
            handleGAEvent('close-list', articleId);
          } else {
            handleGAEvent('expand-list', articleId);
          }
          setIsExpanded((prev) => !prev);
        }}
      >
        <span className={styles.icon}>
          {isExpanded ? (
            <svg
              width='10'
              height='2'
              viewBox='0 0 10 2'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 1H5H9'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          ) : (
            <svg
              width='10'
              height='10'
              viewBox='0 0 10 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 5H5M5 5H9M5 5V9M5 5V1'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}
        </span>
        Read {isExpanded ? 'Less' : 'More'}
      </button>
    </div>
  );
};

export default ArticleSeeMoreButton;
